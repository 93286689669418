body {
  margin: 0;
}

img {
  max-width: 100%;
}

.cardsWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 8px;

  .cardWrap {
    position: relative;
    width: calc((100% - 2*8px - 8px)/3);
    margin: 4px;
    padding: 8px;
    box-sizing: border-box;
    border-radius: var(--vkui--size_card_border_radius--regular);
    background: var(--content_tint_background, var(--vkui--color_background_secondary) );

    @media (max-width: 768px) {
      width: calc((100% - 8px - 8px)/2);
    }

    @media (min-width: 1024px) {
      width: calc((100% - 8px - 8px)/2);
    }

    @media (max-width: 400px) {
      width: 100%
    }

    &:before {
      content: "";
      display: block;
      padding-bottom: 100%;
      box-sizing: border-box;
    }

    .cardWrapContent {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 4px;
      padding: 8px;
      box-sizing: border-box;
    }

    .imageWrap {
      width: 100%;
      height: 100%;

      .viewImage {
        width: 100%;
        height: 100%;
        border-radius: var(--vkui--size_card_border_radius--regular);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center 35%;
      }
    }
  }
}


.accordionWrap {

  .aItemWrapper {

    .aItemTitle {

    }

    .aItemBody {
      height: 0;
      overflow: hidden;
      transition: height ease 0.2s;
    }
  }
}


.LoadingStart {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;

  .lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ripple div {
    position: absolute;
    border: 4px solid #267aa5;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0;
      left: 0;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }
}