.CalendarWrap {

  .CalendarBody {
    margin: 12px;
    padding: 8px;
    background: var(--background_content);
    border-radius: 8px;
    box-shadow: inset 0 0 0 var(--thin-border) var(--input_border);

    .CalendarHeader {
      padding: 6px 0;
      text-align: center;
      font-size: 23px;
    }

    .CalendarRow {
      display: flex;
      justify-content: center;
      flex-grow: 1;

      &.CalendarRow_weekDays {
        padding-bottom: 6px;
      }

      .CalendarItem {
        position: relative;
        text-transform: capitalize;
        color: var(--vkui--color_text_subhead);
        height: 36px;
        flex-basis: calc(100%/7);
        flex-grow: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .CalendarItem_active {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          font-weight: bold;
          border-radius: 14%;
          background: rgba(43, 43, 43, 0.5);
        }
      }
    }
  }
}