.ModalPost {
  position: fixed;
  display: flex;
  justify-content: center;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 90;
  background: rgba(0, 0, 0, 0.7);

  .ModalPostContent {
    position: absolute;
    overflow: auto;
    max-width: 600px;
    min-width: 320px;
    width: 100%;
    height: 100%;
    //bottom: 0;
    //bottom: calc(var(--safe-area-inset-bottom) + 30px);
    //top: calc(var(--safe-area-inset-top) + 56px);
    background: var(--background_content);
  }
}