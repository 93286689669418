.mWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .mImageWrap {
    position: relative;
    width: 100%;
    margin: 4px;
    padding: 8px;
    box-sizing: border-box;
    border-radius: var(--vkui--size_card_border_radius--regular);
    background: var(--content_tint_background, var(--vkui--color_background_secondary) );

    &:before {
      content: "";
      display: block;
      padding-bottom: 100%;
      box-sizing: border-box;
    }

    .mContent {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 4px;
      padding: 8px;
      box-sizing: border-box;
    }

    .viewImage {
      width: 100%;
      height: 100%;
      border-radius: var(--vkui--size_card_border_radius--regular);
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
}